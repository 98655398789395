import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthLayout, HomeLayout, TermsLayout } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
   About,
   Events,
   FormGoodlookero,
   Goodlookero,
   Home,
   Login,
   PrivacyPolicy,
   ServiceDetail,
   ServiceInfo,
   Shopping,
   Success,
   Terms,
   TermsGoodlookero,
} from 'src/pages';

import {
   AppI,
   getAllCategories,
   getAllZones,
   initialStateProducts,
   initialStateZone,
   setClearIntent,
   setCurrentProduct,
   setCurrentZone,
} from 'src/redux';

const AppRouter = () => {
   const dispatch = useAppDispatch();
   const { locale } = useAppSelector(({ app }: { app: AppI }) => app);

   useEffect(() => {
      dispatch(getAllZones());
      dispatch(getAllCategories());
      dispatch(setClearIntent());
      dispatch(setCurrentProduct(initialStateProducts));
      dispatch(setCurrentZone(initialStateZone));
   }, [locale]);

   return (
      <Routes>
         <Route path='/' element={<AuthLayout />}>
            <Route index path='inicio-sesion' element={<Login />} />
         </Route>
         <Route path='/' element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path='servicios/:id' element={<ServiceInfo />} />
            <Route path='servicios/:id/detalle/:idProduct' element={<ServiceDetail />} />
            <Route path='cart' element={<Shopping />} />
            <Route path='eventos' element={<Events />} />
            <Route path='goodlookeros' element={<Goodlookero />} />
            <Route path='nosotros' element={<About />} />
            <Route path='success' element={<Success />} />
            <Route path='formulario-goodlookeros' element={<FormGoodlookero />} />
         </Route>
         <Route path='/' element={<TermsLayout />}>
            <Route path='terminos-uso' element={<Terms />} />
            <Route path='politica-privacidad' element={<PrivacyPolicy />} />
            <Route path='terminos-goodlookeros' element={<TermsGoodlookero />} />
         </Route>
      </Routes>
   );
};

export default AppRouter;
