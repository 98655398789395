import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { Button, Checkbox, Col, DatePicker, Row, Select } from 'antd';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/en';
import InputMask from 'react-input-mask';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, CartI, setUserInfo } from 'src/redux';
import { disabledCurrentDate, emailValidation, hours, ucFirst, numberValidation } from 'src/utils';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import bagI18n from 'src/i18n/bag.i18n.json';
import formI18n from 'src/i18n/form.i18n.json';

import styles from './ShoppingInfo.module.css';

interface ShoppingFormI {
   onSubmited: () => void;
   address: string;
   minimalAmount?: boolean;
}

const ZIP_CODE_MAXLENGTH = 5;

const ShoppingForm = ({ address, onSubmited, minimalAmount = true }: ShoppingFormI) => {
   const dispatch = useAppDispatch();
   const { user, products } = useAppSelector(({ cart }: { cart: CartI }) => cart.data);
   const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

   const [checkedTerms, setCheckedTerms] = useState(false);
   const [isDisabled, setIsDisabled] = useState(true);

   const getValidation = () => {
      if (
         dayjs().add(1, 'day').get('date') === dayjs(dayjs(user.date).format('YYYY-MM-DD'), 'YYYY-MM-DD').get('date') &&
         dayjs().add(1, 'day').get('month') === dayjs(dayjs(user.date).format('YYYY-MM-DD'), 'YYYY-MM-DD').get('month') &&
         dayjs().add(1, 'day').get('year') === dayjs(dayjs(user.date).format('YYYY-MM-DD'), 'YYYY-MM-DD').get('year')
      ) {
         return true;
      }
      return false;
   };

   const onChangeTerms = (e: CheckboxChangeEvent) => {
      setCheckedTerms(e.target.checked);
   };

   useEffect(() => {
      if (
         user.name &&
         user.surName &&
         user.email &&
         emailValidation(user.email) &&
         user.phone &&
         user.phone.replace(/ /g, '').length === 10 &&
         user.phone.indexOf('_') === -1 &&
         user.date &&
         user.hour &&
         user.address &&
         user.addressMunicipality &&
         user.neighboorhood &&
         numberValidation(user.zipCode) &&
         user.zipCode.length === ZIP_CODE_MAXLENGTH &&
         user.addressStreet &&
         user.addressExtNumber &&
         user.alias &&
         user.details &&
         checkedTerms &&
         products.length > 0 &&
         minimalAmount
      ) {
         return setIsDisabled(false);
      }
      setIsDisabled(true);
   }, [
      address,
      checkedTerms,
      user.name,
      user.surName,
      user.email,
      user.date,
      user.hour,
      user.phone,
      user.zipCode,
      user.alias,
      user.details,
      user.addressExtNumber,
      user.addressStreet,
      user.addressMunicipality,
      user.addressIntNumber,
      user.neighboorhood,
      products,
      minimalAmount,
   ]);

   return (
      <>
         <h2>{bagI18n[lang].contactInfo}</h2>
         <form>
            <TextField
               size='small'
               variant='outlined'
               // size='large'
               label={formI18n[lang].namesPlaceHolder}
               onChange={(e) =>
                  dispatch(
                     setUserInfo({
                        ...user,
                        name: e.target.value ? ucFirst(e.target.value) : '',
                     })
                  )
               }
               value={user.name ? user.name : ''}
               style={{ width: '100%', marginBottom: 20 }}
               sx={{
                  '& label': {
                     '&.Mui-focused': {
                        color: '#866F5E',
                     },
                  },
               }}
            />
            <TextField
               size='small'
               variant='outlined'
               label={formI18n[lang].lastNamesPlaceHolder}
               onChange={(e) =>
                  dispatch(
                     setUserInfo({
                        ...user,
                        surName: e.target.value ? ucFirst(e.target.value) : '',
                     })
                  )
               }
               value={user.surName ? user.surName : ''}
               style={{ width: '100%', marginBottom: 20 }}
               sx={{
                  '& label': {
                     '&.Mui-focused': {
                        color: '#866F5E',
                     },
                  },
               }}
            />
            <TextField
               size='small'
               variant='outlined'
               // size='large'
               type='email'
               label={formI18n[lang].emailPlaceholder}
               onChange={(e) =>
                  dispatch(
                     setUserInfo({
                        ...user,
                        email: e.target.value ? e.target.value.trim() : '',
                     })
                  )
               }
               value={user.email ? user.email : ''}
               error={user.email && emailValidation(user.email) ? false : user.email !== '' ? true : false}
               // status={user.email && emailValidation(user.email) ? '' : user.email !== '' ? 'error' : ''}
               helperText={user.email && emailValidation(user.email) ? '' : user.email !== '' ? 'Formato invalido' : ''}
               style={{ width: '100%', marginBottom: 20 }}
               sx={{
                  '& label': {
                     '&.Mui-focused': {
                        color: '#866F5E',
                     },
                  },
               }}
            />
            <InputMask
               mask='99 9999 9999'
               onChange={(e) =>
                  dispatch(
                     setUserInfo({
                        ...user,
                        phone: e.target.value ? e.target.value : '',
                     })
                  )
               }
               value={user.phone ? user.phone : ''}>
               <TextField
                  size='small'
                  variant='outlined'
                  label={formI18n[lang].phonePlaceholder}
                  error={user.phone && user.phone.indexOf('_') === -1 ? false : user.phone !== '' ? true : false}
                  helperText={formI18n[lang].phoneAuxText}
                  style={{ width: '100%', marginBottom: 20 }}
                  sx={{
                     '& label': {
                        '&.Mui-focused': {
                           color: '#866F5E',
                        },
                     },
                  }}
               />
            </InputMask>
            <h2>{bagI18n[lang].serviceData}</h2>
            <div>
               <h4>Ciudad: {user.address}</h4>
            </div>
            <br />
            <Row gutter={[10, 0]}>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     type='zipcode'
                     label={`${formI18n[lang].zipCodePlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              zipCode: numberValidation(e.target.value) ? e.target.value.trim() : '',
                           })
                        )
                     }
                     value={user.zipCode ? user.zipCode : ''}
                     inputProps={{
                        maxLength: 5,
                     }}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='municipality'
                     label={`${formI18n[lang].municipalityPlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              addressMunicipality: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.addressMunicipality ? user.addressMunicipality : ''}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='neighboorhood'
                     label={`${formI18n[lang].neighboorhoodPlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              neighboorhood: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.neighboorhood ? user.neighboorhood : ''}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='street'
                     label={`${formI18n[lang].streetPlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              addressStreet: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.addressStreet ? user.addressStreet : ''}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='extnumber'
                     label={`${formI18n[lang].extNumberPlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              addressExtNumber: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.addressExtNumber ? user.addressExtNumber : ''}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='intnumber'
                     label={formI18n[lang].intNumberPlaceholder}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              addressIntNumber: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.addressIntNumber ? user.addressIntNumber : ''}
                     inputProps={{
                        maxLength: 5,
                     }}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='details'
                     label={`${formI18n[lang].detailsPlaceholder}*`}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              details: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.details ? user.details : ''}
                     inputProps={{
                        maxLength: 30,
                     }}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
               <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TextField
                     size='small'
                     variant='outlined'
                     id='alias'
                     label={formI18n[lang].aliasPlaceholder}
                     onChange={(e) =>
                        dispatch(
                           setUserInfo({
                              ...user,
                              alias: e.target.value ? e.target.value : '',
                           })
                        )
                     }
                     value={user.alias ? user.alias : ''}
                     inputProps={{
                        maxLength: 30,
                     }}
                     style={{ width: '100%', marginBottom: 20 }}
                     sx={{
                        '& label': {
                           '&.Mui-focused': {
                              color: '#866F5E',
                           },
                        },
                     }}
                  />
               </Col>
            </Row>
            <h4>{formI18n[lang].bookingLabel}</h4>
            <Row gutter={[10, 0]}>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <DatePicker
                     size='large'
                     placeholder={formI18n[lang].datePlaceholder}
                     style={{ width: '100%' }}
                     locale={lang === 'es' ? localeEs : undefined}
                     disabledDate={disabledCurrentDate}
                     value={user.date ? dayjs(dayjs(user.date), 'YYYY-MM-DD') : null}
                     onChange={(date) => {
                        dispatch(
                           setUserInfo({
                              ...user,
                              hour: '',
                              date: date ? dayjs(date).toString() : '',
                           })
                        );
                     }}
                  />
               </Col>
               <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Select
                     placeholder={formI18n[lang].hourPlaceholder}
                     size='large'
                     style={{ minWidth: '100%' }}
                     options={
                        user.date && getValidation()
                           ? hours.filter((i) => {
                                // current hour
                                const ch = new Date().getHours();
                                // difference hour
                                const h = ch;
                                // array string -> splitted hour and minutes
                                const sp = i.value.split(':');
                                if (h < Number(sp[0])) {
                                   if (Number(sp[0]) - h === 1) {
                                      return i;
                                   } else if (Number(sp[0]) - h > 1) {
                                      return i;
                                   }
                                }
                             })
                           : hours
                     }
                     onChange={(val: any) => {
                        dispatch(
                           setUserInfo({
                              ...user,
                              hour: val,
                           })
                        );
                     }}
                     value={user.hour ? user.hour : undefined}
                  />
               </Col>
            </Row>
            {/* <br />
            <Checkbox style={{ marginBottom: 10 }} checked={checkedInfo} onChange={onChangeSaveInfo}>
                Guardar mi información para futuros servicios
            </Checkbox> */}
            <br />
            <Row>
               <Col>
                  <Checkbox checked={checkedTerms} onChange={onChangeTerms}>
                     {formI18n[lang].agreeText}
                  </Checkbox>
               </Col>
               <Col style={{ paddingTop: 3 }}>
                  <a href='/terminos-uso' rel='noreferrer' target='_blank'>
                     {formI18n[lang].termsText}
                  </a>
               </Col>
            </Row>
            <br />
            <div className={styles.cart_container_btn}>
               <Button size='large' type='primary' ghost disabled={isDisabled} onClick={() => onSubmited()}>
                  {formI18n[lang].btnSchedule}
               </Button>
            </div>
         </form>
      </>
   );
};
export default ShoppingForm;
