import React from 'react';
import ReactDOM from 'react-dom/client';

import 'antd/dist/reset.css';
import TagManager from 'react-gtm-module';

import './index.css';
import Providers from './providers';

import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
   gtmId: 'GTM-T4CR7HH8',
};

// initialize tag manager
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Providers />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
